const baseUrl = 'https://api.worldfoodchain.io/api/v1';

export const config = {
    apiUrl: baseUrl,

    //auth
    user: {
        login: baseUrl+'/user/login',
    },

    //categories
    categories: {
        list: baseUrl+'/listing/categories',
        create: baseUrl+'/listing/categories',
        get: baseUrl+'/listing/categories/:id',

        tree: baseUrl+'/listing/categories/tree'
    },

    //countries
    countries: {
        list: baseUrl+'/settings/countries',
        create: baseUrl+'/settings/countries',
        get: baseUrl+'/settings/countries/:id',
    },

    //products
    products: {
        list: baseUrl+'/listing/products',
        create: baseUrl+'/listing/products',
        get: baseUrl+'/listing/products/:id',
        upload: baseUrl+'/listing/products/upload/:id',
    }
}

export const units = [
    'MT', 'Boxes', 'Nos', 'Dozen', 'Kg' 
].sort();