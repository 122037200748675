import { responseConstants, authConstants } from '../_constants';
import { authService } from '../_services';
import { responseActions } from './';
import { redirectTo } from '../_helpers';

export const authActions = {
    login,
    logout
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        authService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));

                    // check the user type and redirect to different sections later
                    redirectTo('/dashboard');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(responseActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: authConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: authConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function logout() {
    authService.logout();
    return { type: responseConstants.SUCCESS };
}