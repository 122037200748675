import { responseConstants } from '../_constants';

export const responseActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: responseConstants.SUCCESS, message };
}

function error(message) {
    return { type: responseConstants.ERROR, message };
}

function clear() {
    return { type: responseConstants.CLEAR };
}