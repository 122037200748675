import {config} from '../config';
import { authHeader, handleResponse } from '../_helpers';

export class crudService {
    constructor(model) {
        this.model = model;
    }

    list = (page, filters) => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        if (typeof page === 'undefined')
            page = 1;

        if (typeof filters === 'undefined')
            filters = {};

        let url = new URL(config[this.model].list);

        Object.keys(filters).forEach(key => url.searchParams.append(key, filters[key]))
        url.searchParams.append('page', page);

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
    }

    get = (id) => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${config[this.model].get.replace(':id', id)}`, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
    }

    create = (payload) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(payload)
        };

        return fetch(`${config[this.model].create}`, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
    }

    update = (id, payload) => {
        const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(payload)
        };

        return fetch(`${config[this.model].get.replace(':id', id)}`, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
    }

    remove = (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader()
        };

        return fetch(`${config[this.model].get.replace(':id', id)}`, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
    }

    uploadFile = (files, id) => {
        if (!Array.isArray(files))
            files = [files];

        const data = new FormData();
        files.map(file => {
            data.append('file[]', file, file.name);
        })        

        const requestOptions = {
            method: 'POST',
            headers: authHeader('file-upload'),
            body: data,
        };

        return fetch(config[this.model].upload.replace(':id', id), requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
    }
}
