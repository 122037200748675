export function authHeader(type) {
    if (typeof type === 'undefined')
        type = '';

    let defaultHeaders = {}
    if (type !== 'file-upload') {        
        defaultHeaders = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('userData'));

    if (user && user.token) {
        return { ...defaultHeaders, 'Authorization': 'Bearer ' + user.token };
    } else {
        return defaultHeaders;
    }
}