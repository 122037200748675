import {config} from '../config';
import { authHeader, handleResponse } from '../_helpers';

export const countryService = {
    list,
    get,
    create,
    update,
    remove
};

function list(page)
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.countries.list}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function get(id)
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.countries.get.replace(':id', id)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function create(payload)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(`${config.countries.create}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function update(id, payload)
{
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(`${config.countries.get.replace(':id', id)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function remove(id)
{
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.countries.get.replace(':id', id)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}