import { authService } from '../_services';

export function handleResponse(response) {
    return response.text().then(text => {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            authService.logout();
        }

        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}