import {config} from '../config';
import { authHeader, handleResponse } from '../_helpers';

export const categoryService = {
    list,
    get,
    create,
    update,
    remove,
    updateDocumentList,
    getCategoryTree
};

function list(page)
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.categories.list}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function get(id)
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.categories.get.replace(':id', id)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function create(payload)
{
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(`${config.categories.create}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function update(id, payload)
{
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(`${config.categories.get.replace(':id', id)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function remove(id)
{
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.categories.get.replace(':id', id)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function updateDocumentList(id, documents)
{
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({documents: documents})
    };

    return fetch(`${config.categories.get.replace(':id', id)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getCategoryTree()
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.categories.tree}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}