import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { authActions } from '../../../_actions';

class Login extends PureComponent {  
  constructor(props) {
    super(props);

    // reset login status
    this.props.logout();
  }

  handleSubmit = (values, setSubmitting) => {
    const {email, password} = values;
    this.props.login(email, password);
  }

  handleError = () => {
    const {loggingInFailed} = this.props;

    if (loggingInFailed)
      document.getElementById('login-form').reset();
  }

  componentDidUpdate () {
    this.handleError();
  }
  
  render() {
    const {loggingIn, loggingInFailed} = this.props;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card>
                  <CardBody>
                  <Formik
                    initialValues={{ email: '', password: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                      this.handleSubmit(values, setSubmitting);
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email().required('Required'),
                      password: Yup.string().required('Required').min(8),
                    })}
                  >
                    {props => {
                      const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                      } = props;
                      return (
                        <Form id="login-form" className="needs-validation" onSubmit={handleSubmit}>
                          <div className="text-center"><img src={'../../assets/img/logo.svg'} style={{ maxWidth: "100px" }} /></div>
                          <h1 className="text-center text-primary" style={{ fontWeight: 700, marginBottom: 0 }}>World Food Chain</h1>
                          <div className="p-4">
                            {
                              loggingInFailed && (
                                <p className="error text-center">Invalid Username or Password</p>
                              )
                            }
                            <p className="text-muted text-center">Sign In to your account</p>
                            <FormGroup className={errors.email && touched.email ? 'error' : ''}>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-user"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input id="email" type="text" placeholder="Username" value={values.email} autoComplete="username" onChange={handleChange} onBlur={handleBlur} />
                              </InputGroup>
                              {errors.email && touched.email && (
                                <div className="input-feedback">
                                  {errors.email}
                                </div>
                              )}
                            </FormGroup>
                            <FormGroup className={errors.password && touched.password ? 'error' : ''}>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-lock"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input id="password" type="password" placeholder="Password" autoComplete="current-password" onChange={handleChange} onBlur={handleBlur} />
                              </InputGroup>
                              {errors.password && touched.password && (
                                <div className="input-feedback">
                                  {errors.password}
                                </div>
                              )}
                            </FormGroup>
                            <Row>
                              <Col xs="6">
                                <Button type="submit" disabled={loggingIn} color="primary" className="px-4">
                                  Login
                                </Button>
                                {loggingIn && (
                                  <i className="fa fa-spinner fa-spin fa-lg ml-4"></i>
                                )}
                              </Col>
                              <Col xs="6" className="text-right">
                                <Button color="link" className="px-0">Forgot password?</Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      );
                    }}
                    </Formik>
                  </CardBody>
                </Card>
                
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapState(state) {
  const { loggingIn, loggingInFailed } = state.authentication;
  return { loggingIn, loggingInFailed };
}

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout
};

const connectedLogin = connect(mapState, actionCreators)(Login);
export { connectedLogin as Login };